@import url("https://fonts.googleapis.com/css2?family=Merienda&display=swap");

.wrapper {
  display: flex;
}
.quote {
  font-family: "Merienda", cursive;
  padding: 0 2%;
  /* font-family: 'SamsungOne',arial !important; */
}

.navigation {
  width: 100%;
  display: flex;
  justify-content:space-between;
  padding: 2%;
  background: white;
  position: relative;
}
.navigation h1 {
  font-family: 'SamsungSharpSans',arial !important;
  color: #1428A0;
}
.navigation .nav-button {
  position: absolute;
  right: 2%;
  background: #0077c8;
  color: white;
}

.navigation .nav-button:hover {
  background: #0077c8;
}

.main-input {
  max-width: 500px;
}

@media (max-width: 600px) {
  .navigation{
   padding: 4%;
}
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.fade {
    animation: fadein 1s ease-in-out;
}

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.message {
  padding: 2em;
  margin: 1em 0;
  background-color: #fff;
  -webkit-box-shadow: 11px 10px 5px 0px rgba(204, 204, 204, 0.43);
  -moz-box-shadow: 11px 10px 5px 0px rgba(204, 204, 204, 0.43);
  box-shadow: 11px 10px 5px 0px rgba(204, 204, 204, 0.43);
  border-radius: 25px;
}

.image {
    cursor: pointer;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
    -webkit-box-shadow: 11px 10px 5px 0px rgba(204, 204, 204, 0.43);
  -moz-box-shadow: 11px 10px 5px 0px rgba(204, 204, 204, 0.43);
  box-shadow: 11px 10px 5px 0px rgba(204, 204, 204, 0.43);
}

.image:hover {
    transition: all 0.3s ease-in-out;
    -webkit-box-shadow: 11px 10px 7px 0px rgba(204, 204, 204, 0.43);
    -moz-box-shadow: 11px 10px 7px 0px rgba(204, 204, 204, 0.43);
    box-shadow: 11px 10px 5px 7px rgba(204, 204, 204, 0.43);
}