@import url(https://fonts.googleapis.com/css2?family=Merienda&display=swap);

.wrapper {
  display: flex;
}
.quote {
  font-family: "Merienda", cursive;
  padding: 0 2%;
  /* font-family: 'SamsungOne',arial !important; */
}

.navigation {
  width: 100%;
  display: flex;
  justify-content:space-between;
  padding: 2%;
  background: white;
  position: relative;
}
.navigation h1 {
  font-family: 'SamsungSharpSans',arial !important;
  color: #1428A0;
}
.navigation .nav-button {
  position: absolute;
  right: 2%;
  background: #0077c8;
  color: white;
}

.navigation .nav-button:hover {
  background: #0077c8;
}

.main-input {
  max-width: 500px;
}

@media (max-width: 600px) {
  .navigation{
   padding: 4%;
}
}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.fade {
    -webkit-animation: fadein 1s ease-in-out;
            animation: fadein 1s ease-in-out;
}

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.message {
  padding: 2em;
  margin: 1em 0;
  background-color: #fff;
  box-shadow: 11px 10px 5px 0px rgba(204, 204, 204, 0.43);
  border-radius: 25px;
}

.image {
    cursor: pointer;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
  box-shadow: 11px 10px 5px 0px rgba(204, 204, 204, 0.43);
}

.image:hover {
    transition: all 0.3s ease-in-out;
    box-shadow: 11px 10px 5px 7px rgba(204, 204, 204, 0.43);
}
* {
    box-sizing: border-box;
  }
  
  html,
  body,
  #root {
    margin: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  #root {
    overflow: auto;
    display: flex;
    justify-content: center;
    background: #f0f0f0;
  
  }
  
  .list {
    position: relative;
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto,
      segoe ui, arial, sans-serif;
    width: 100%;
    height: 100%;
  }
  
  .list > div {
    position: absolute;
    will-change: transform, width, height, opacity;
    padding: 15px;
  }
  
  .list > div > div {
    position: relative;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 10px;
    border-radius: 4px;
    box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.2);
  }
  
